<template>
  <div>
    <div class="issue">
      <h2>常见问题</h2>
      <div class="issue_top">
        <div v-for="item in problemList" :key="item.article_id">
          <p style="cursor: pointer;" @click="getList(item.article_id)">{{ item.title }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getHotProblem } from "../../../api/serve";

export default {
  data() {
    return {
      problemList: [],
    };
  },
  created() {
    this.getServe();
  },
  methods: {
    // 热门问题
    getServe() {
      const params = {
        page: 1,
        size: 6,
      };
      getHotProblem(params).then((res) => {
        this.problemList = res.data.code;
      });
    },
    // 跳转
    getList(id) {
      this.$router.push({ name: "issueAbout", query: { id } });
    },
  },
};
</script>

<style lang="scss" scoped>
.issue {
  text-align: center;
  margin-top: 6.125rem;
  min-height: 20rem;
  h2 {
    font-size: 1.5625vw;
    font-weight: 500;
    margin: 5.0417vw 0;
  }
  .issue_top {
    display: flex;
    flex-flow: wrap;
    justify-content: space-around;
    div {
      margin: 0 3.125rem;
    }
    p {
      font-size: 1.3542vw;
      padding: 0.625rem;
      border-bottom: 1px solid #ccc;
    }
  }
}
::v-deep .el-tabs--right .el-tabs__nav-wrap.is-right::after {
  height: 0;
}
::v-deep .el-tabs--right .el-tabs__item.is-right {
  font-size: 1.25rem;
}
</style>
